// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TabsComponent.css */

.slide-left-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slide-left-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slide-left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .slide-right-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slide-right-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slide-right-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TabsComponent.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;IACI,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;IACxB,0CAA0C;EAC5C;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,2BAA2B;IAC3B,0CAA0C;EAC5C;;EAEA;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;IACxB,0CAA0C;EAC5C;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,4BAA4B;IAC5B,0CAA0C;EAC5C","sourcesContent":["/* TabsComponent.css */\n\n.slide-left-enter {\n    opacity: 0;\n    transform: translateX(-100%);\n  }\n  .slide-left-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: opacity 300ms, transform 300ms;\n  }\n  .slide-left-exit {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  .slide-left-exit-active {\n    opacity: 0;\n    transform: translateX(100%);\n    transition: opacity 300ms, transform 300ms;\n  }\n  \n  .slide-right-enter {\n    opacity: 0;\n    transform: translateX(100%);\n  }\n  .slide-right-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: opacity 300ms, transform 300ms;\n  }\n  .slide-right-exit {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  .slide-right-exit-active {\n    opacity: 0;\n    transform: translateX(-100%);\n    transition: opacity 300ms, transform 300ms;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
