// CartModal.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, SwipeableDrawer, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, TextField, Grid, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { IMaskInput } from 'react-imask';
import { useCart } from '../context/CartContext'; // Импортируем хук useCart

const PhoneMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (000) 000-00-00"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const CartModal = ({ isOpen, onClose }) => {
    const { cart, products, handleChangeQuantity, getTotalCartPrice, clearCart } = useCart(); // Используем хук useCart

    const [name, setName] = useState(() => localStorage.getItem('orderName') || '');
    const [phone, setPhone] = useState(() => localStorage.getItem('orderPhone') || '');
    const [street, setStreet] = useState(() => localStorage.getItem('orderStreet') || '');
    const [house, setHouse] = useState(() => localStorage.getItem('orderHouse') || '');
    const [apartment, setApartment] = useState(() => localStorage.getItem('orderApartment') || '');
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const isPhoneValid = phone.replace(/[^0-9]/g, '').length === 11;
        setIsFormValid(name.trim() !== '' && isPhoneValid && street.trim() !== '' && house.trim() !== '');
    }, [name, phone, street, house]);

    useEffect(() => {
        localStorage.setItem('orderName', name);
        localStorage.setItem('orderPhone', phone);
        localStorage.setItem('orderStreet', street);
        localStorage.setItem('orderHouse', house);
        localStorage.setItem('orderApartment', apartment);
    }, [name, phone, street, house, apartment]);

    const inputProps = {
        style: {
            height: '45px',
        }
    };

    const inputLabelProps = {
        sx: {
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s',
            '&.MuiInputLabel-shrink': {
                top: -3,
                left: 0,
                transform: 'translate(14px, -6px) scale(0.75)',
                transition: 'none',
            },
        }
    };

    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const handleQuantityChange = (productId, change) => {
        handleChangeQuantity(productId, change);
    };

    const handleOrderSubmit = () => {
        console.log('Оформление заказа');
        console.log('Текущее состояние корзины:', cart);
        console.log('Имя:', name);
        console.log('Улица:', street);
        console.log('Дом:', house);
        console.log('Квартира:', apartment);
        console.log('Телефон:', phone);

        // Здесь можно добавить логику отправки заказа на сервер

        // После успешного оформления заказа:
        clearCart();
        onClose();
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={onClose}
            onOpen={() => {}}
            disableSwipeToOpen={true}
            PaperProps={{
                style: {
                    borderTopLeftRadius: '25px',
                    borderTopRightRadius: '25px',
                    height: '90%',
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto' }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>Себет</Typography>
                    <Alert severity="info" sx={{ mb: 2, borderRadius: '15px' }}>
                        Назар аударыңыз: тапсырыс рәсімделгеннен кейін сатушы сізбен WhatsApp арқылы тікелей байланысып, жеткізу уақыты мен шарттарын нақтылайды.
                    </Alert>
                    {Object.keys(cart).length === 0 ? (
                        <Typography sx={{ px: '15px' }}>Сіздің себетіңіз бос</Typography>
                    ) : (
                        <List sx={{ pt: 0 }}>
                            {Object.entries(cart).map(([productId, quantity], index, array) => {
                                const parsedProductId = parseInt(productId, 10);
                                const product = products.find(p => p.id === parsedProductId);
                                if (!product) {
                                    console.warn(`Продукт с ID ${productId} не найден`);
                                    return null;
                                }
                                return (
                                    <React.Fragment key={productId}>
                                        <ListItem sx={{ py: 2, px: 0 }}>
                                            <ListItemAvatar>
                                                <Avatar src={product.photoUrl} alt={product.label} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={product.label}
                                                secondary={`₸ ${formatNumber(product.sellPrice)} x ${quantity} = ₸ ${formatNumber(product.sellPrice * quantity)}`}
                                                primaryTypographyProps={{ fontSize: '0.8rem' }}
                                            />
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {quantity === 0 ? (
                                                    <IconButton onClick={() => handleQuantityChange(productId, 1)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                ) : (
                                                    <>
                                                        <IconButton onClick={() => handleQuantityChange(productId, -1)}>
                                                            {quantity === 1 ? <DeleteIcon /> : <RemoveIcon />}
                                                        </IconButton>
                                                        <Typography sx={{ mx: 1 }}>{quantity}</Typography>
                                                        <IconButton onClick={() => handleQuantityChange(productId, 1)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </Box>
                                        </ListItem>
                                        {index < array.length - 1 && (
                                            <Divider
                                                sx={{
                                                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                                    height: '1px'
                                                }}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    )}
                </Box>
                <Box sx={{ p: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'left', fontSize: '1rem' }}>
                        Төлеуге жалпы сома: ₸ {formatNumber(getTotalCartPrice())}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Аты"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Телефон нөмірі"
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                InputProps={{
                                    ...inputProps,
                                    inputComponent: PhoneMaskCustom,
                                }}
                                InputLabelProps={inputLabelProps}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Көше"
                                variant="outlined"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Үй"
                                variant="outlined"
                                value={house}
                                onChange={(e) => setHouse(e.target.value)}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Пәтер"
                                variant="outlined"
                                value={apartment}
                                onChange={(e) => setApartment(e.target.value)}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!isFormValid}
                        onClick={handleOrderSubmit}
                        sx={{
                            mt: 2,
                            height: '50px',
                            borderRadius: '25px',
                            backgroundColor: 'black',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#333'
                            },
                            '&:disabled': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                color: 'rgba(0, 0, 0, 0.26)'
                            },
                            position: 'relative',
                            fontSize: '0.85rem',
                            textTransform: 'none'
                        }}
                    >
                        {('Тапсырыс беру').charAt(0).toUpperCase() + ('Тапсырыс беру').slice(1)}
                    </Button>
                </Box>
            </Box>
        </SwipeableDrawer>
    );
};

export default CartModal;
