import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru';
import Avatar from 'react-avatar';
import { FaStar } from 'react-icons/fa';

const Reviews = ({ reviews }) => {
  const initialReviewsPerPage = 5;
  const [reviewsPerPage, setReviewsPerPage] = useState(initialReviewsPerPage);
  const [displayedReviews, setDisplayedReviews] = useState([]);

  const loadMoreReviews = useCallback(() => {
    if (!reviews || reviews.length === 0) return;

    const sortedReviews = [...reviews].sort((a, b) => new Date(b.created) - new Date(a.created));
    setDisplayedReviews(sortedReviews.slice(0, reviewsPerPage));
  }, [reviews, reviewsPerPage]);

  useEffect(() => {
    loadMoreReviews();
  }, [loadMoreReviews]);

  const handleShowMoreReviews = () => {
    setReviewsPerPage((prevReviewsPerPage) => prevReviewsPerPage + initialReviewsPerPage);
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    if (date.isSame(moment(), 'day')) {
      return `Сегодня в ${date.format('HH:mm')}`;
    } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
      return `Вчера в ${date.format('HH:mm')}`;
    } else if (date.isSame(moment(), 'year')) {
      return date.format('D MMMM в HH:mm');
    } else {
      return date.format('D MMMM YYYY года в HH:mm');
    }
  };

  if (!reviews || reviews.length === 0) {
    return <p>No reviews available</p>;
  }

  return (
      <Box sx={{ mt: 1 }}>
        {displayedReviews.map((review, index) => (
            <Card
                key={index}
                sx={{
                  p: 0,
                  pl: 1,
                  pr: 1,
                  pt: 1,
                  pb: 1,
                  backgroundColor: '#FFF',
                  borderRadius: '15px',
                  mb: 2,
                  boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.0)',
                }}
            >
              <CardContent sx={{ px: 2 }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                    <Avatar name={review.name} size={30} round={true} />
                    <Typography variant="body1">{review.name}</Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    {formatDate(review.created)}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} mt={2}>
                  {Array(5).fill(0).map((_, index) => (
                      <FaStar key={index} color={index < review.grade ? '#ffd700' : 'gray'} />
                  ))}
                </Box>
                <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
                  {review.review}
                </Typography>
              </CardContent>
            </Card>
        ))}
        <Box display="flex" justifyContent="center" mt={2}>
          {reviews.length > displayedReviews.length && (
              <Button
                  variant="text"
                  onClick={handleShowMoreReviews}
                  sx={{
                    color: '#1976d2',
                    '&:hover': {
                      color: '#1565c0',
                    },
                    textTransform: 'none', // убирает капитализацию
                  }}
              >
                Көбірек көрсету
              </Button>
          )}
        </Box>
      </Box>
  );
};

export default Reviews;
