// src/hooks/useProducts.jsx
import { useState, useEffect } from 'react';
import apiService from '../api/api_service';

export const useProducts = (code) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            if (code) {
                try {
                    setLoading(true);
                    const response = await apiService.getCardProducts(code);
                    if (Array.isArray(response) && response.length > 0) {
                        setProducts(response);
                    } else {
                        setError('Получены некорректные данные');
                        setProducts([]);
                    }
                } catch (error) {
                    setError('Ошибка при получении продуктов');
                    setProducts([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setError('Код не определен');
                setLoading(false);
            }
        };
        fetchProducts();
    }, [code]);

    return { products, loading, error };
};
