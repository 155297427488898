export const useStyles = () => ({
    drawerPaper: {
        height: '80%',
        backgroundColor: 'transparent',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    modalContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
    },
    swiper: {
        height: '100%',
        width: '100%',
    },
    slideImage: (photoUrl) => ({
        width: '100%',
        height: '100%',
        backgroundImage: `url(${photoUrl || ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }),
    slideVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        padding: '4px',
        zIndex: 2,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
    },
    shareButton: {
        position: 'absolute',
        top: '50px',
        right: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        padding: '4px',
        zIndex: 2,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
    },
    productInfo: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        backdropFilter: 'blur(10px)',
        padding: '20px',
        boxShadow: '0 -4px 30px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    pagination: {
        position: 'absolute',
        bottom: 'calc(100% + 10px)',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 2,
    },
    paginationDot: (isActive) => ({
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: isActive ? '#fff' : 'rgba(255, 255, 255, 0.5)',
        margin: '0 4px',
        transition: 'background-color 0.3s ease',
    }),
    productTitle: {
        lineHeight: 1.2,
        mb: 1,
        color: 'black',
    },
    productPrice: {
        fontWeight: 'bold',
        color: 'black',
    },
    productUnit: {
        color: 'rgba(0, 0, 0, 0.7)',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 2,
    },
    quantityControl: {
        display: 'flex',
        alignItems: 'center',
        width: '30%',
        justifyContent: 'flex-start',
    },
    quantityText: {
        mx: 1,
        color: 'black',
    },
    addToCartButton: (quantity) => ({
        width: '68%',
        borderRadius: '25px',
        backgroundColor: quantity === 0 ? 'black' : 'white',
        color: quantity === 0 ? 'white' : 'black',
        '&:hover': {
            backgroundColor: quantity === 0 ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        },
    }),
});
