import React, { useState } from 'react';
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Home from './pages/Home';
import ReviewForm from './components/ReviewForm';
import ProductModal from './components/ProductModal';
import { useProducts } from './hooks/useProducts';
import './App.css';
import { CartProvider } from './context/CartContext';

// Создаем компонент редиректа
const RedirectExternal = ({ url }) => {
    React.useEffect(() => {
        window.location.replace(url);
    }, [url]);

    return null;
};

// Компонент-обертка для ProductModal
const ProductModalWrapper = () => {
    const { code, id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(true);
    const { products, loading, error } = useProducts(code);

    const handleClose = () => {
        setIsOpen(false);
        navigate(`/merchant/${code}${location.search}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const product = products.find(p => String(p.id) === String(id));

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <ProductModal
            isOpen={isOpen}
            onClose={handleClose}
            product={product}
            merchantCode={code}
        />
    );
};

const App = () => {
    return (
        <CartProvider>
            <CssBaseline />
            <Routes>
                <Route path="/" element={<RedirectExternal url="https://merchant.ozenfinance.com" />} />
                <Route path="/merchant/:code" element={<Home />}>
                    <Route path="product/:id" element={<ProductModalWrapper />} />
                </Route>
                <Route path="/merchant/:code/leave-review" element={<ReviewForm />} />
            </Routes>
        </CartProvider>
    );
};

export default App;
