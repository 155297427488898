import ApiClient from './api_client';

class ApiService {
  static cardInfoPath = '/api/v1/public/business-card/';
  static _bodyParam = 'body';
  static cardProductsPath = '/api/v1/public/merchant/card/';

  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCardInfo(code) {
    try {
      console.log('Fetching card info for code:', code);
      const response = await this.apiClient.get(`${ApiService.cardInfoPath}${code}`);
      console.log('Received card info response:', response);
      return response[ApiService._bodyParam];
    } catch (error) {
      console.error('Error fetching card info:', error);
      throw error;
    }
  }

  async getCardProducts(code) {
    try {
      console.log('Fetching card products for code:', code);
      const response = await this.apiClient.get(`${ApiService.cardProductsPath}${code}/products`);
      console.log('Received card products response:', response);

      if (Array.isArray(response)) {
        return response.map(product => ({
          ...product,
          photoUrl: product.medias.find(media => media.type === 'photo')?.url,
          videoUrl: product.medias.find(media => media.type === 'video')?.url
        }));
      } else if (response && Array.isArray(response.data)) {
        return response.data.map(product => ({
          ...product,
          photoUrl: product.medias.find(media => media.type === 'photo')?.url,
          videoUrl: product.medias.find(media => media.type === 'video')?.url
        }));
      } else if (response && typeof response === 'object') {
        // Если ответ - объект, попробуем найти массив внутри него
        const possibleArrays = Object.values(response).filter(Array.isArray);
        if (possibleArrays.length > 0) {
          return possibleArrays[0].map(product => ({
            ...product,
            photoUrl: product.medias.find(media => media.type === 'photo')?.url,
            videoUrl: product.medias.find(media => media.type === 'video')?.url
          }));
        }
      }

      console.error('Unexpected response structure for card products:', response);
      return [];
    } catch (error) {
      console.error('Error fetching card products:', error);
      throw error;
    }
  }

  async getProductById(merchantCode, productId) {
    try {
      console.log(`Fetching product info for merchant ${merchantCode} and product ${productId}`);
      const products = await this.getCardProducts(merchantCode);
      const product = products.find(p => p.id === parseInt(productId));

      if (product) {
        console.log('Found product:', product);
        return product;
      } else {
        console.error(`Product with id ${productId} not found`);
        return null;
      }
    } catch (error) {
      console.error('Error fetching product info:', error);
      throw error;
    }
  }

  // Здесь можно добавить другие методы API по мере необходимости
}

// Создаем экземпляр ApiService
const apiService = new ApiService();

// Экспортируем созданный экземпляр
export default apiService;
