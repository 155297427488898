// src/context/AppServiceContext.js
import React, { createContext, useContext } from 'react';
import apiService from '../api/api_service';

const AppServiceContext = createContext();

const AppServiceProvider = ({ children }) => {
  return (
      <AppServiceContext.Provider value={{ apiService }}>
        {children}
      </AppServiceContext.Provider>
  );
};

const useAppService = () => {
  const context = useContext(AppServiceContext);
  if (context === undefined) {
    throw new Error('useAppService must be used within an AppServiceProvider');
  }
  return context.apiService;
};

export { AppServiceProvider, AppServiceContext, useAppService };
