// ProductModal.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Button, IconButton, SwipeableDrawer, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useCart } from '../context/CartContext';

// Импорт стилей Swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Импорт стилей компонента
import { useStyles } from './ProductModal.styles';

const ProductModal = ({ isOpen, onClose, product, merchantCode }) => {
    const styles = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const swiperRef = useRef(null);
    const { cart, handleAddToCart, handleChangeQuantity } = useCart();

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (isOpen && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) {
            setActiveIndex(0);
        }
    }, [isOpen]);

    if (!isOpen || !product) {
        return null;
    }

    const slidesCount = product.videoUrl ? 2 : 1;

    const handleAddToCartClick = () => {
        if (product && product.id) {
            handleAddToCart(product, 1);
        }
    };

    const handleChangeQuantityClick = (change) => {
        if (product && product.id) {
            handleChangeQuantity(product.id, change);
        }
    };

    const handleShare = async () => {
        const shareUrl = window.location.href;
        const shareTitle = product.label || 'Интересный товар';
        const shareText = `Мұнда тек базардың ең жақсысы! Тауарды делдалсыз тікелей сатушыдан тапсырыс бер: ${shareUrl} – ${shareTitle}.`;

        if (navigator.share) {
            try {
                await navigator.share({
                    text: shareText,
                });
            } catch (error) {
                console.error('Ошибка при шеринге:', error);
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarOpen(true);
        }
    };

    const quantity = cart[product.id] || 0;

    return (
        <>
            <SwipeableDrawer
                anchor="bottom"
                open={isOpen}
                onClose={handleClose}
                onOpen={() => {}}
                disableSwipeToOpen={false}
                swipeAreaWidth={56}
                disableBackdropTransition
                disableDiscovery
                transitionDuration={300}
                PaperProps={{
                    style: styles.drawerPaper,
                }}
            >
                <Box sx={styles.modalContainer}>
                    <Swiper
                        ref={swiperRef}
                        modules={[Navigation, Pagination]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={slidesCount > 1}
                        onSlideChange={(swiper) => {
                            setActiveIndex(swiper.realIndex);
                            const videos = swiper.el.querySelectorAll('video');
                            videos.forEach(video => {
                                if (swiper.realIndex === 1) {
                                    video.play().catch(e => console.error("Error playing video:", e));
                                } else {
                                    video.pause();
                                    video.currentTime = 0;
                                }
                            });
                        }}
                        style={styles.swiper}
                    >
                        <SwiperSlide>
                            <Box sx={styles.slideImage(product.photoUrl)} />
                        </SwiperSlide>
                        {product.videoUrl && (
                            <SwiperSlide>
                                <Box
                                    component="video"
                                    sx={styles.slideVideo}
                                    src={product.videoUrl}
                                    muted
                                    playsInline
                                    loop
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleClose();
                        }}
                        sx={styles.closeButton}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        onClick={handleShare}
                        sx={styles.shareButton}
                    >
                        <ShareIcon fontSize="small" />
                    </IconButton>
                    <Box sx={styles.productInfo}>
                        <Box sx={styles.pagination}>
                            {[...Array(slidesCount)].map((_, index) => (
                                <Box
                                    key={index}
                                    sx={styles.paginationDot(index === activeIndex)}
                                />
                            ))}
                        </Box>
                        <Box>
                            <Typography id="product-modal-title" variant="h6" component="h2" sx={styles.productTitle}>
                                {product.label || 'Название товара отсутствует'}
                            </Typography>
                            <Typography variant="body1" sx={styles.productPrice}>
                                ₸ {(product.sellPrice || 0).toLocaleString()}
                            </Typography>
                            <Typography variant="body2" sx={styles.productUnit}>
                                {product.measureUnit || 'Ед. изм. не указана'}
                            </Typography>
                        </Box>
                        <Box sx={styles.actionContainer}>
                            <Box sx={styles.quantityControl}>
                                <IconButton onClick={() => handleChangeQuantityClick(-1)} disabled={quantity === 0}>
                                    <RemoveIcon />
                                </IconButton>
                                <Typography sx={styles.quantityText}>{quantity}</Typography>
                                <IconButton onClick={() => handleChangeQuantityClick(1)}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                            <Button
                                variant="contained"
                                onClick={handleAddToCartClick}
                                sx={styles.addToCartButton(quantity)}
                            >
                                {quantity === 0 ? 'Себетке қосу' : 'Себетте'}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Не удалось поделиться. Попробуйте другой способ."
            />
        </>
    );
};

export default ProductModal;
