import React from 'react';
import { Box, Paper, Typography, Button, LinearProgress } from '@mui/material';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RatingsSummary = ({ reviewCount, gradeMap, code }) => {
    const navigate = useNavigate();
    const summary = calculateSummary(reviewCount, gradeMap);

    return (
        <Paper sx={styles.paper}>
            <Typography variant="h6" sx={styles.title}>
                Бағалар мен пікірлер
            </Typography>
            <AverageRating avgRating={summary.avgRating} totalReviews={summary.totalReviews} />

            <Box sx={styles.contentContainer}>
                <RatingBars summary={summary} />

                <Button
                    variant="outlined"
                    sx={styles.button}
                    onClick={() => navigate(`/merchant/${code}/leave-review`)}
                >
                    Пікір қалдыру
                </Button>
            </Box>
        </Paper>
    );
};

const RatingBars = ({ summary }) => (
    <>
        {[5, 4, 3, 2, 1].map((num) => (
            <RatingBar
                key={num}
                rating={num}
                count={summary.ratingsCount[num - 1]}
                total={summary.totalReviews}
            />
        ))}
    </>
);

const RatingBar = ({ rating, count, total }) => (
    <Box display="flex" alignItems="center" sx={styles.ratingBar}>
        <Typography variant="h16" sx={styles.ratingNumber}>
            {rating}
        </Typography>
        <Box sx={styles.progressBarContainer}>
            <LinearProgress
                variant="determinate"
                value={(count / total) * 100 || 0}
                sx={{
                    ...styles.progressBar,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#ffd700',
                    },
                }}
            />
        </Box>
        <Typography variant="h12" sx={styles.ratingCount}>
            {count}
        </Typography>
    </Box>
);

const AverageRating = ({ avgRating, totalReviews }) => (
    <Box display="flex" flexDirection="column" alignItems="center" sx={styles.averageRating}>
        <Box display="flex" alignItems="center">
            {Array(5)
                .fill(0)
                .map((_, index) => (
                    <FaStar
                        key={index}
                        size={20}
                        style={{
                            color: index < Math.floor(avgRating) ? '#ffd700' : '#ccc',
                            marginLeft: 4,
                        }}
                    />
                ))}
        </Box>
        <Typography variant="h12" sx={styles.totalReviews}>
            {totalReviews} {getReviewWord(totalReviews)}
        </Typography>
    </Box>
);

const calculateSummary = (reviewCount, gradeMap) => {
    if (!reviewCount || !gradeMap) {
        return { totalReviews: 0, ratingsCount: Array(5).fill(0), avgRating: 0 };
    }

    const summary = { totalReviews: reviewCount, ratingsCount: Array(5).fill(0) };
    let totalRating = 0;

    Object.entries(gradeMap).forEach(([grade, count]) => {
        const gradeNumber = parseInt(grade, 10);
        summary.ratingsCount[gradeNumber - 1] = count;
        totalRating += gradeNumber * count;
    });

    summary.avgRating = totalRating / summary.totalReviews || 0;
    return summary;
};

const getReviewWord = (count) => {
    const lastDigit = count % 10;
    const secondLastDigit = Math.floor((count % 100) / 10);

    if (secondLastDigit === 1) return 'отзывов';
    if (lastDigit === 1) return 'отзыв';
    if (lastDigit >= 2 && lastDigit <= 4) return 'отзыва';
    return 'отзывов';
};

const styles = {
    paper: {
        textAlign: 'center',
        p: 2,
        backgroundColor: '#FFF',
        borderRadius: 4,
        mb: 2,
        boxShadow: 'none',
    },
    title: { fontWeight: 'bold' },
    subtitle: { marginTop: 2, fontWeight: 'bold', fontSize: 'larger' },
    ratingBar: { mt: 1, width: '100%' },
    ratingNumber: { minWidth: 20, fontSize: 'larger' },
    progressBarContainer: { width: '80%', mx: 1 },
    progressBar: {
        height: 10,
        borderRadius: 5,
        backgroundColor: '#E0E0E0',
    },
    ratingCount: { minWidth: 20, fontSize: 'larger' },
    averageRating: { mt: 2 },
    avgRatingNumber: { fontWeight: 'bold', fontSize: 'larger', marginRight: 4 },
    starIcon: { color: '#FFA726', ml: 1 },
    totalReviews: { mt: 0, fontSize: 'larger' },
    button: {
        borderColor: '#000',
        mt: 2,
        width: 'calc(100% - 10px)', // Ширина на всю ширину виджета с отступами по 15px с каждой стороны
        height: '50px', // Высота кнопки 50px
        borderRadius: '50px',
        color: '#000',
        boxShadow: 'none',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
};

export default RatingsSummary;
