import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from './context/SnackbarContext';
import { AppServiceProvider } from './context/AppServiceContext';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AppServiceProvider>
            <SnackbarProvider>
                <App />
            </SnackbarProvider>
        </AppServiceProvider>
    </BrowserRouter>
);
