// CartContext.jsx
import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : {};
    });

    const [products, setProducts] = useState([]);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const handleAddToCart = useCallback((product, quantity) => {
        console.log(`Добавление товара в корзину: ${product.label}, количество: ${quantity}`);
        setCart(prevCart => {
            const newQuantity = (prevCart[String(product.id)] || 0) + quantity;
            console.log(`Новая корзина:`, { ...prevCart, [String(product.id)]: newQuantity });
            return { ...prevCart, [String(product.id)]: newQuantity };
        });
    }, []);

    const handleChangeQuantity = useCallback((productId, change) => {
        console.log(`Изменение количества для продукта ID ${productId}: ${change}`);
        setCart(prevCart => {
            const newQuantity = (prevCart[String(productId)] || 0) + change;
            if (newQuantity <= 0) {
                const { [String(productId)]: _, ...rest } = prevCart;
                return rest;
            }
            return { ...prevCart, [String(productId)]: newQuantity };
        });
    }, []);

    const getTotalCartPrice = useCallback(() => {
        return Object.entries(cart).reduce((total, [productId, quantity]) => {
            const product = products.find(p => String(p.id) === productId);
            return total + (product ? product.sellPrice * quantity : 0);
        }, 0);
    }, [cart, products]);

    const clearCart = useCallback(() => {
        console.log('Очистка корзины');
        setCart({});
    }, []);

    const setProductsData = useCallback((newProducts) => {
        setProducts(newProducts);
    }, []);

    const cartItemsCount = useMemo(() => {
        return Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);
    }, [cart]);

    const contextValue = useMemo(() => ({
        cart,
        products,
        handleAddToCart,
        handleChangeQuantity,
        getTotalCartPrice,
        clearCart,
        setProductsData,
        cartItemsCount
    }), [cart, products, handleAddToCart, handleChangeQuantity, getTotalCartPrice, clearCart, setProductsData, cartItemsCount]);

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = React.useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
