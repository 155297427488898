import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ReviewsPage from '../pages/ReviewsPage';
import CatalogPage from '../pages/CatalogPage';
import { useSnackbar } from '../context/SnackbarContext';
import './TabsComponent.css';

const TabsComponent = ({ cardInfo }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [direction, setDirection] = useState('right');
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    console.log('TabsComponent cardInfo:', cardInfo);
  }, [cardInfo]);

  const handleTabChange = useCallback((newValue) => {
    setDirection(activeTab < newValue ? 'right' : 'left');
    setActiveTab(newValue);
  }, [activeTab]);

  const handleError = useCallback((error) => {
    console.error('Error in TabsComponent:', error);
    showSnackbar('Произошла ошибка при загрузке данных', 'error');
    setIsLoading(false);
  }, [showSnackbar]);

  const tabs = [
    {
      label: 'Пікірлер',
      component: <ReviewsPage cardInfo={cardInfo} onError={handleError} />
    },
    {
      label: 'Каталог',
      component: cardInfo ? (
          <CatalogPage
              cardInfo={cardInfo}
              onError={handleError}
              setIsLoading={setIsLoading}
          />
      ) : (
          <Typography>Нет данных</Typography>
      )
    }
  ];

  return (
      <Box sx={styles.container}>
        <TabButtons activeTab={activeTab} onTabChange={handleTabChange} tabs={tabs} />
        {isLoading ? (
            <Box sx={styles.loadingContainer}>
              <CircularProgress />
            </Box>
        ) : (
            <TabContent activeTab={activeTab} direction={direction} tabs={tabs} cardInfo={cardInfo} />
        )}
      </Box>
  );
};

const TabButtons = React.memo(({ activeTab, onTabChange, tabs }) => (
    <Box sx={styles.buttonContainer}>
      {tabs.map((tab, index) => (
          <TabButton
              key={index}
              label={tab.label}
              isActive={activeTab === index}
              onClick={() => onTabChange(index)}
          />
      ))}
    </Box>
));

const TabButton = React.memo(({ label, isActive, onClick }) => (
    <Button
        onClick={onClick}
        sx={styles.tabButton(isActive)}
    >
      <Typography>{label}</Typography>
    </Button>
));

const TabContent = React.memo(({ activeTab, direction, tabs, cardInfo }) => {
  const nodeRef = React.useRef(null);
  return (
      <Box sx={styles.content}>
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
              key={activeTab}
              nodeRef={nodeRef}
              timeout={300}
              classNames={direction === 'left' ? 'slide-left' : 'slide-right'}
          >
            <Box ref={nodeRef} sx={styles.tabContentWrapper}>
              {tabs[activeTab].component}
            </Box>
          </CSSTransition>
        </SwitchTransition>
      </Box>
  );
});

const styles = {
  container: {
    width: '100%',
    px: 0,
    pb: 2
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2
  },
  tabButton: (isActive) => ({
    width: 'calc(50% - 7.5px)',
    height: 40,
    p: 2,
    bgcolor: isActive ? 'black' : 'transparent',
    color: isActive ? 'white' : 'black',
    border: '1px solid black',
    borderRadius: '25px',
    transition: 'all 0.3s ease',
    '&:hover': {
      bgcolor: isActive ? 'black' : 'transparent',
      color: isActive ? 'white' : 'black',
    },
  }),
  content: {
    width: '100%',
    mt: 2
  },
  tabContentWrapper: {
    width: '100%',
    mx: 'auto'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
};

export default TabsComponent;
