// CatalogPage.jsx
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Grid, Button, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useProducts } from '../hooks/useProducts';
import ProductCard from '../components/ProductCard';
import ProductModal from '../components/ProductModal';
import CartModal from '../components/CartModal';
import CategoryTabs from '../components/CategoryTabs';
import { useCart } from '../context/CartContext';

const CartButton = styled(Button)(({ theme }) => ({
    position: 'fixed',
    bottom: '10px',
    left: '15px',
    right: '15px',
    height: '50px',
    borderRadius: '25px',
    padding: theme.spacing(1, 3),
    backgroundColor: 'black',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Уменьшенное значение z-index
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

const CartText = styled(Typography)({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textTransform: 'none',
});

const CartPrice = styled(Typography)({
    position: 'absolute',
    right: '25px',
    color: 'rgba(255, 255, 255, 0.7)',
});

const StyledModal = styled(Box)({
    zIndex: 1400, // Значение больше, чем у кнопки корзины
});

const AnimatedCartModal = ({ isOpen, onClose, ...props }) => {
    return (
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
            <StyledModal>
                <CartModal isOpen={isOpen} onClose={onClose} {...props} />
            </StyledModal>
        </Slide>
    );
};

const CatalogPage = () => {
    const { code } = useParams();
    const { products, loading, error } = useProducts(code);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const navigate = useNavigate();
    const location = useLocation();
    const isInitialMount = useRef(true);
    const { cart, getTotalCartPrice } = useCart();

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];
        const productId = parseInt(lastSegment, 10);

        if (!isNaN(productId)) {
            const product = products.find(p => p.id === productId);
            if (product && product.photoUrl) {
                console.log('Product found, opening modal:', product);
                setSelectedProduct(product);
                setIsModalOpen(true);
            } else {
                console.log('Product not found or missing required data, closing modal and navigating to /merchant/:code');
                setIsModalOpen(false);
                setSelectedProduct(null);
                navigate(`/merchant/${code}`, { replace: true });
            }
        } else {
            console.log('No valid productId in URL, closing modal');
            setIsModalOpen(false);
            setSelectedProduct(null);
        }
    }, [location, products, code, navigate]);

    const categories = useMemo(() => {
        if (!products) return [];
        const categorySet = new Set(products.map(product => product.category));
        return ['all', ...Array.from(categorySet)];
    }, [products]);

    const filteredProducts = useMemo(() => {
        if (selectedCategory === 'all') {
            return products.filter(product => product.photoUrl);
        } else {
            return products.filter(product => product.category === selectedCategory && product.photoUrl);
        }
    }, [products, selectedCategory]);

    const handleOpenModal = (product) => {
        if (product && product.photoUrl) {
            console.log('Opening modal for product:', product);
            setSelectedProduct(product);
            setIsModalOpen(true);
            navigate(`/merchant/${code}/product/${product.id}`, { replace: true });
        } else {
            console.log('Product is missing required data, cannot open modal');
        }
    };

    const handleCloseModal = () => {
        console.log('Closing modal');
        setIsModalOpen(false);
        setSelectedProduct(null);
        navigate(`/merchant/${code}`, { replace: true });
    };

    const handleOpenCartModal = () => {
        console.log('Opening cart modal');
        setIsCartModalOpen(true);
    };

    const handleCloseCartModal = () => {
        console.log('Closing cart modal');
        setIsCartModalOpen(false);
    };

    if (loading) return <Typography variant="body1" sx={{ textAlign: 'center', pt: 2 }}>Загрузка...</Typography>;
    if (error) return <Typography variant="body1" color="error" sx={{ textAlign: 'center', pt: 2 }}>{error}</Typography>;
    if (!products || products.length === 0) return <Typography variant="body1" sx={{ textAlign: 'center', pt: 2 }}>Каталог товаров пока пуст.</Typography>;

    return (
        <Box sx={{ width: '100%', mt: 0, pb: 10 }}>
            <CategoryTabs
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={setSelectedCategory}
            />
            <Box sx={{ px: 0, mt: 2 }}>
                <Grid container spacing={2}>
                    {filteredProducts.map((product) => (
                        <Grid item key={product.id} xs={4} sm={4} md={3} lg={2}>
                            <ProductCard
                                product={product}
                                onOpenModal={handleOpenModal}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {selectedProduct && (
                <StyledModal>
                    <ProductModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        product={selectedProduct}
                        merchantCode={code}
                    />
                </StyledModal>
            )}

            {Object.values(cart).reduce((sum, quantity) => sum + quantity, 0) > 0 && (
                <CartButton onClick={handleOpenCartModal}>
                    <CartText variant="button" sx={{ fontWeight: 'normal' }}>
                        Себет
                    </CartText>
                    <CartPrice variant="body1">
                        ₸{getTotalCartPrice().toLocaleString()}
                    </CartPrice>
                </CartButton>
            )}

            <AnimatedCartModal
                isOpen={isCartModalOpen}
                onClose={handleCloseCartModal}
            />
        </Box>
    );
};

export default CatalogPage;
